













import { Component, Vue } from "vue-property-decorator";
import { getCSRFToken } from "../../services/actions";
import PasswordResetForm from "@views/components/PasswordResetForm.vue";
import axios, { AxiosError, AxiosResponse } from "axios";

@Component({ components: { PasswordResetForm } })
export default class PasswordReset extends Vue {}
